<template>
  <Live/>
</template>

<script>
import Live from './components/Live.vue'
export default {
  name: 'App',
  components: {
    Live
  }
}
</script>

<style>
</style>
